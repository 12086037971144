enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    VERIFY_AUTH = "verifyAuth",
    LOGIN = "login",
    LOGOUT = "logout",
    REGISTER = "register",
    UPDATE_USER = "updateUser",
    FORGOT_PASSWORD = "forgotPassword",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    //Получить тарифик пользователя
    GET_USER_DOWNLOAD_UPLOAD_TRAFFIC = "getUserDownloadUploadTraffic",
    //Активировать услугу Кредит
    ACTIVATE_CREDIT = "activateCredit",
    //Активировать услугу DrWeb
    ACTIVATE_DRWEB = "activateDrWeb",
    //Изменить тариф DrWeb
    CHANGE_DRWEB_TARIFF = "changeDrWebTariff",
    //Отключить DrWeb
    DISABLE_DRWEB_TARIFF = "disableDrWebTariff",
    //Изменить тарифный план
    CHANGE_TARIFF = "changeTariff",
    //Получить список проплат пользвоателя
    USER_PAYMENT_LIST = "userPaymentsList",
    //Запрос по балансу
    NO_MONEY_SEND_REQUEST = "noMoneySendRequest",
    //Получить список заявок
    GET_USER_REQUESTS_DATA = "getUserRequestsList",
    //Добавить новую заявку
    ADD_NEW_REQUEST = "addNewRequest",
    SET_REQUEST_RATING = "setRequestRating",
    CANCEL_REQUEST = "cancelUserRequest",
    ADD_NEW_NOTE_TO_REQUEST = "addNewNoteToRequest",
    //Avatar
    REMOVE_AVATAR = "removeUserAvatar",
    //Pfofile
    CHANGE_PROFILE_PARAMS = "changeProfileParams",
    //Disable profile
    DISABLE_PROFILE = "disableProfile",
    //Enable profile
    ENABLE_PROFILE = "enableProfile",

    //Set web socket connection
    WEB_SOCKET_CONNECTION = "webSocketConnection",

    //Online messages
    ADD_ONLINE_MESSAGE = "onAddOnlineMessage",

    //News added
    NEWS_WAS_ADD_NEW_ITEM = "onNewsWasAddNewItem",

    //User device status
    SET_USER_DEVICE_OFFLINE_STATUS = "setUserDeviceStatus",

    //Private messages list
    SET_PRIVATE_MESSAGES_LIST = "setPrivateMessagesList",

    //New transaction added in auto payment
    ON_NEW_TRANSACTION_ADDED = "onNewTransactionAdded",

    //Поиск пользователя для перевода средств
    ON_SEARCH_USER_TO_SEND_MONEY = "onSearchUserToSendMoney",

    //Отправка баланса пользователю
    ON_SEND_MONEY_TO_USER = "onSendMoneyToUser",

    //Отмечаем что есть новые сообщения (за текущий день)
    ON_HAS_NEW_PRIVATE_MESSAGES = "onHasNewPrivateMessages",

    //Использование карты
    ON_USAGE_CARD = "onUsageCard",


}

enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_ERROR = "setError",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
    SET_DOWNLOAD_UPLOAD_USER_TRAFFIC = "downloadUploadUserTraffic",
    SET_USER_PAYMENTS_LIST = "setUserPaymentsList",
    SET_USER_REQUESTS_DATA = "setUserRequestsData",

    SET_HAS_WEB_SOCKET_CONNECTION = "hasWebSocketConnection",

    ADD_ONLINE_MESSAGE = "addOnlineMessage",

    CHANGE_USER_DEVICE_OFFLINE_STATUS = "changeUserDeviceStatus",

    SET_PRIVATE_MESSAGES_LIST = "mSetPrivateMessagesList",

    //New transaction added in auto payment
    ON_NEW_TRANSACTION_ADDED = "mOnNewTransactionAdded",

    //Поиск пользователя для перевода средств
    ON_SEARCH_USER_TO_SEND_MONEY = "mOnSearchUserToSendMoney",

    //Отмечаем что есть новые сообщения (за текущий день)
    ON_HAS_NEW_PRIVATE_MESSAGES = "mOnHasNewPrivateMessages",

    //Использование карты
    ON_USAGE_CARD = "mOnUsageCard",
}

enum OnlineMessagesTypes {
    NEW_PAYMENT = "new-payment",
    NEWS_WAS_ADD_NEW_ITEM = "news-new-item",

    DEVICE_OFFLINE = "devices-offline",
    DEVICE_ONLINE = "devices-online",

    MESSAGE_FROM_TELEGRAM = "messages-from-telegram",

    MESSAGE_FROM_WORKER = "message-from-worker",

    NEW_PAYMENT_TRANSACTION_ADDED = "new-payment-transaction-added",

    ON_NEW_REQUEST_ADDED = "on-new-request-added",
    ON_REQUEST_DELETED = "on-request-deleted",
    ON_REQUEST_STATUS_CHANGED = "on-request-status-changed",

    ON_HAS_MONEY_HELP = "on-send-user-money",

    ON_MONEY_HELP = "on-send-money-from-user",

    ON_USE_PAYMENT_CARD = "on-use-payment-card",
}

enum RequestStatus {
    IN_PROGRESS = 5,
    CANCELLED = 2,
    COMPLETED = 0,
}

export {Actions, Mutations, OnlineMessagesTypes, RequestStatus,};
