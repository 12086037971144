import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    api_token: string;
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
    users: unknown;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};

    user: any = {};
    basicDataOfUser: any = {};

    isAuthenticated = !!JwtService.getToken();
    users = {};
    token = "";
    downloadUploadTraffic = {};
    paymentsList = null;
    paymentsStatistics = null;
    requestsData = null;
    webSocketConnectionStatus = false;

    //Список сообщений в онлайне (сообщения отображаются за сеанс)
    onlineMessagesList: any = [];

    userDeviceOfflineStatus = false;

    newPaymentTransaction = null;

    userDataWhoGetMoney: any = null;

    has_new_private_messages = false;

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Get basic data of current user object
     * @returns User
     */
    get getBasicDataOfUser(): User {
        return this.basicDataOfUser;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    get getDownloadUploadUserTraffic() {
        return this.downloadUploadTraffic;
    }

    get getUserPaymentsList() {
        return this.paymentsList;
    }

    get getPaymentsStatistics() {
        return this.paymentsStatistics;
    }

    get getUserRequestsData() {
        return this.requestsData;
    }

    get getWebSocketConnectionStatus() {
        return this.webSocketConnectionStatus;
    }

    get getOnlineMessagesList() {
        return this.onlineMessagesList;
    }

    get getUserDeviceOfflineStatus() {
        return this.userDeviceOfflineStatus;
    }

    get getPaymentTransaction() {
        return this.newPaymentTransaction;
    }

    get getUserWhoGetMoney() {
        return this.userDataWhoGetMoney;
    }

    get isHasNewPrivateMessages() {
        return this.has_new_private_messages;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_DOWNLOAD_UPLOAD_USER_TRAFFIC](data) {
        this.downloadUploadTraffic = data;
    }

    @Mutation
    [Mutations.SET_AUTH](users_data) {
        this.isAuthenticated = true;

        this.users = users_data;
        if (users_data.inet != null) {
            this.user = users_data.inet;
            this.token = users_data.api_key;

            //Только базовые данные, делаем обьект нереактивным, без дальнейших изменений
            this.basicDataOfUser = JSON.parse(JSON.stringify(users_data.inet));
        }

        this.errors = {};

        JwtService.saveToken(this.token);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];

        this.downloadUploadTraffic = {};
        this.paymentsList = null;
        this.paymentsStatistics = null;
        this.requestsData = null;

        //Список сообщений в онлайне (сообщения отображаются за сеанс)
        this.onlineMessagesList = [];
        this.userDeviceOfflineStatus = false;
        this.newPaymentTransaction = null;
        this.has_new_private_messages = false;
        this.userDataWhoGetMoney = null;

        JwtService.destroyToken();
    }

    @Mutation
    [Mutations.SET_USER_PAYMENTS_LIST](data) {
        this.paymentsList = data.payments;
        this.paymentsStatistics = data.statistics;
    }

    @Mutation
    [Mutations.SET_USER_REQUESTS_DATA](data) {
        this.requestsData = data;
    }

    @Mutation
    [Mutations.SET_HAS_WEB_SOCKET_CONNECTION](data) {
        this.webSocketConnectionStatus = data;
    }

    @Mutation
    [Mutations.ADD_ONLINE_MESSAGE](data) {
        this.onlineMessagesList.unshift(data);
    }

    @Mutation
    [Mutations.CHANGE_USER_DEVICE_OFFLINE_STATUS](data) {
        this.userDeviceOfflineStatus = data;
    }

    @Mutation
    [Mutations.SET_PRIVATE_MESSAGES_LIST](payload) {
        this.onlineMessagesList = payload;
    }

    @Mutation
    [Mutations.ON_NEW_TRANSACTION_ADDED](payload) {
        this.newPaymentTransaction = payload;
    }

    @Mutation
    [Mutations.ON_SEARCH_USER_TO_SEND_MONEY](payload) {
        this.userDataWhoGetMoney = payload;
    }

    @Mutation
    [Mutations.ON_HAS_NEW_PRIVATE_MESSAGES](payload) {
        this.has_new_private_messages = payload;
    }

    @Action
    [Actions.LOGIN](credentials) {
        return ApiService.post("users/login-check", credentials)
            .then(({data}) => {
                if (data.errors != undefined && Object.keys(data!.errors).length > 0) {
                    this.context.commit(Mutations.SET_ERROR, data.errors);
                } else {
                    this.context.commit(Mutations.SET_AUTH, data);
                }
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        return ApiService.post("register", credentials)
            .then(({data}) => {
                this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return ApiService.post("forgot_password", payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH](payload) {
        if (JwtService.getToken()) {
            //ApiService.setHeader();
            ApiService.post("users/verify-user", payload)
                .then(({data}) => {
                    if (
                        data.errors != undefined &&
                        Object.keys(data!.errors).length > 0
                    ) {
                        this.context.commit(Mutations.SET_ERROR, data.errors);
                    } else {
                        this.context.commit(Mutations.SET_AUTH, data);
                    }
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.GET_USER_DOWNLOAD_UPLOAD_TRAFFIC](payload) {
        if (JwtService.getToken()) {
            //ApiService.setHeader();
            ApiService.post("users/get-user-traffic", payload).then(({data}) => {
                if (payload.fn != undefined) {
                    payload.fn(data);
                }

                this.context.commit(Mutations.SET_DOWNLOAD_UPLOAD_USER_TRAFFIC, data);
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ACTIVATE_CREDIT](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/activate-client-credit", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ACTIVATE_DRWEB](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/activate-drweb-client", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.DISABLE_DRWEB_TARIFF](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/disable-drweb-client", payload).then(
                ({data}) => {
                    9;
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.CHANGE_TARIFF](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/change-client-tariff", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.USER_PAYMENT_LIST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/get-users-payments-data", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER_PAYMENTS_LIST, data.data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.NO_MONEY_SEND_REQUEST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/no-money-request", payload).then(({data}) => {
                if (data.success) {
                    this.context.commit(Mutations.SET_USER, data.data);
                }

                if (payload.fn != undefined) {
                    payload.fn(data);
                }
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.GET_USER_REQUESTS_DATA](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-requests-data", payload).then(({data}) => {
                if (data.success) {
                    this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                }
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ADD_NEW_REQUEST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-add-new-request", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.SET_REQUEST_RATING](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-request-rating", payload).then(({data}) => {
                if (data.success) {
                    this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                }

                if (payload.fn != undefined) {
                    payload.fn(data);
                }
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.CANCEL_REQUEST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-cancel-request", payload).then(({data}) => {
                if (data.success) {
                    this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                }

                if (payload.fn != undefined) {
                    payload.fn(data);
                }
            });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ADD_NEW_NOTE_TO_REQUEST](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-add-new-note-to-request", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER_REQUESTS_DATA, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.REMOVE_AVATAR](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-remove-avatar", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.CHANGE_PROFILE_PARAMS](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-change-params", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.DISABLE_PROFILE](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-disable-profile", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ENABLE_PROFILE](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-enable-profile", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.WEB_SOCKET_CONNECTION](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/user-close-page", payload).then(
                ({data}) => {
                    this.context.commit(Mutations.SET_HAS_WEB_SOCKET_CONNECTION, payload.connection);
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }

    }

    @Action
    [Actions.ADD_ONLINE_MESSAGE](payload: any) {
        this.context.commit(Mutations.ADD_ONLINE_MESSAGE, payload);
    }

    @Action
    [Actions.NEWS_WAS_ADD_NEW_ITEM](payload: any) {
        this.context.commit(Mutations.ADD_ONLINE_MESSAGE, payload);
    }

    @Action
    [Actions.SET_USER_DEVICE_OFFLINE_STATUS](payload: any) {
        this.context.commit(Mutations.CHANGE_USER_DEVICE_OFFLINE_STATUS, payload);
    }

    @Action
    [Actions.SET_PRIVATE_MESSAGES_LIST](payload: any) {
        this.context.commit(Mutations.SET_PRIVATE_MESSAGES_LIST, payload);
    }

    @Action
    [Actions.ON_NEW_TRANSACTION_ADDED](payload) {
        this.context.commit(Mutations.ON_NEW_TRANSACTION_ADDED, payload);
    }

    @Action
    [Actions.ON_SEARCH_USER_TO_SEND_MONEY](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/search-user-to-send-money", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.ON_SEARCH_USER_TO_SEND_MONEY, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ON_SEND_MONEY_TO_USER](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/send-money-to-user", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.ON_HAS_NEW_PRIVATE_MESSAGES](payload) {
        this.context.commit(Mutations.ON_HAS_NEW_PRIVATE_MESSAGES, payload);
    }

    @Action
    [Actions.ON_USAGE_CARD](payload) {
        if (JwtService.getToken()) {
            ApiService.post("users/on-usage-payment-card", payload).then(
                ({data}) => {
                    if (data.success) {
                        this.context.commit(Mutations.SET_USER, data.data);
                    }

                    if (payload.fn != undefined) {
                        payload.fn(data);
                    }
                }
            );
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }
}
